import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import sr from '@utils/sr';
import { srConfig } from '@config';
import styled from 'styled-components';
import { theme, mixins, media, Section, Heading } from '@styles';
const { colors } = theme;

const StyledContainer = styled(Section)`
  position: relative;
`;
const StyledFlexContainer = styled.div`
  ${mixins.flexBetween};
  align-items: flex-start;
  ${media.tablet`display: block;`};
`;

const StyledPic = styled.div`
  position: relative;
  width: 100%;
  max-width: 200px;
  margin-left: 30px;
  ${media.tablet`margin: 60px auto 0;`};
  ${media.phablet`width: 70%;`};
  a {
    &:focus {
      outline: 0;
    }
  }
`;

const StyledAvatar = styled(Img)`
  position: relative;
  mix-blend-mode: multiply;
  min-height: 200px;
  filter: grayscale(100%) contrast(1);
  border-radius: ${theme.borderRadius};
  transition: ${theme.transition};
`;

const StyledAvatarLink = styled.a`
  ${mixins.boxShadow};
  width: 100%;
  position: relative;
  border-radius: ${theme.borderRadius};
  background-color: ${colors.green};
  margin-left: -20px;
  &:hover,
  &:focus {
    background: transparent;
    &:after {
      top: 15px;
      left: 15px;
    }
    ${StyledAvatar} {
      filter: none;
      mix-blend-mode: normal;
    }
  }
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: ${theme.borderRadius};
    transition: ${theme.transition};
  }
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.navy};
    mix-blend-mode: screen;
  }
  &:after {
    border: 2px solid ${colors.green};
    top: 20px;
    left: 20px;
    z-index: -1;
  }
`;

const Sponsors = ({ data }) => {
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  const sponsors = data.filter(({ node }) => node);

  return (
    <StyledContainer id="sponsors" ref={revealContainer}>
      <Heading>My Sponsors</Heading>
      <StyledFlexContainer>
        {sponsors.map(({ node }, i) => {
          const { frontmatter } = node;
          const { github, avatar } = frontmatter;
          return (
            <StyledPic key={i}>
              <StyledAvatarLink target="_blank" href={github}>
                <StyledAvatar fluid={avatar.childImageSharp.fluid} alt="Avatar" />
              </StyledAvatarLink>
            </StyledPic>
          );
        })}
      </StyledFlexContainer>
    </StyledContainer>
  );
};

Sponsors.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Sponsors;
